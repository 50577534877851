<template>
  <div>
    <search-inner @submit-search="search" @clear-search="reset">
      <el-col :span="6">
        <el-form-item label="用户名">
          <el-input v-model="searchForm.loginName" placeholder="请输入要搜索的用户名" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="员工姓名">
          <el-input v-model="searchForm.userName" placeholder="请输入要搜索的员工姓名" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="手机号">
          <el-input v-model="searchForm.phone" placeholder="请输入要搜索的手机号" />
        </el-form-item>
      </el-col>
    </search-inner>
    <ics-table-inner v-loading="loading" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" class="tableClass" @changePageNum="changePageNum">
      <template slot="btn-inner">
        <el-button type="primary" @click="$router.push({ name: 'systemManagementEmployeeEdit', params: { editMode: 'add' }, query: { appKey: tableKey.appKey, tableActiveName: tableActiveName } })">
          添加用户
        </el-button>
      </template>
      <template slot="table-columns-before">
        <el-table-column fixed="left" label="序号" type="index" />
      </template>
      <template slot="table-columns-after">
        <el-table-column fixed="right" label="操作" width="140">
          <template v-slot="scope">
            <el-link v-if="scope.row.status === '10'" type="primary" class="text-btn" @click="enableOrDisable(scope.row.employeeCode, '10')">
              启用
            </el-link>
            <el-link v-if="scope.row.status === '20'" type="danger" class="text-btn" @click="enableOrDisable(scope.row.employeeCode, '20')">
              禁用
            </el-link>
            <el-link v-if="tableActiveName === 'tenant'" type="primary" class="text-btn" @click="$router.push({ name: 'systemManagementEmployeeEdit', params: { editMode: 'edit' }, query: { appKey: tableKey.appKey, employeeCode: scope.row.employeeCode, tableActiveName: tableActiveName } })">
              编辑
            </el-link>
            <el-link type="primary" class="text-btn" @click="$router.push({ name: 'systemManagementEmployeeDetail', query: { employeeCode: scope.row.employeeCode } })">
              查看
            </el-link>
          </template>
        </el-table-column>
      </template>
    </ics-table-inner>
  </div>
</template>
<script>
import IcsTableInner from '@/components/table-inner'
export default {
  name: 'IcsEmployeeListInner',
  components: { IcsTableInner },
  props: {
    processList: {
      type: Array,
      default () {
        return []
      }
    },
    tableKey: {
      type: Object,
      default () {
        return {}
      }
    },
    tableActiveName: {
      type: String,
      default: ''
    },
    tableList: {
      type: Object,
      default () {
        return {
          list: []
        }
      }
    },
    viewMode: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchForm: {
        loginName: '',
        userName: '',
        phone: ''
      },
      columnOption: [
        { label: '用户名', prop: 'account', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 140 },
        { label: '姓名', prop: 'name', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '所属角色', prop: 'roleList', formatter: (row, col, cell) => this.utils.roleFormat(cell), minWidth: 320 },
        { label: '手机号码', prop: 'mobile', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'status', formatter: (row, col, cell) => this.utils.statusFormat(cell, 'employeeStatus'), minWidth: 140 },
      ],
      dialog: {
        menu: false
      }
    }
  },
  computed: {},
  methods: {
    search() {
      this.$emit('search', this.searchForm)
    },
    reset() {
      this.searchForm.loginName = ''
      this.searchForm.userName = ''
      this.searchForm.phone = ''
      this.$emit('search', this.searchForm)
    },
    changePageNum({pageNum}) {
      this.searchForm.pageNum = pageNum
      this.$emit('search', this.searchForm)
    },
    enableOrDisable (employeeCode, status) {
      let api = ''
      let success = ''
      if (status === '10') {
        api = this.api.system.employee.enable
        success = '已启用'
      } else {
        api = this.api.system.employee.disable
        success = '已禁用'
      }
      api(employeeCode).then(result => {
        if (result.data.success === true) {
          this.$message.success(success)
          this.search()
        } else {
          this.$message.error(result.data.message)
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
